// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")
require('components/carousel')
require('components/home')
require('components/h-scroll')
require('components/infinite_scroll')
require('components/menu')
require('components/single-model')
require('components/slider')
require('components/vimeo-oembed')
require('components/masonry')

//= require will_paginate_infinite

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
import fadeInScroll from "../components/fade-in-scroll.js"
import fadeInStagger from "../components/fade-in-stagger.js"

import LazyLoad from "vanilla-lazyload";

window.gsap = gsap // for js.erb

const FADEINTIME = 1;
document.addEventListener('DOMContentLoaded', ()=> {
	gsap.to(['body','html'], FADEINTIME, {opacity: 1, ease: Power4.easeInOut} )
	fadeInScroll();
	fadeInStagger();
	watchForHover(); 
	isSafari(); 
	var lazyLoadInstance = new LazyLoad({
		elements_selector: ".lazy",
        cancel_on_exit: true,
        use_native: false
	  });
})

function isSafari() {
	var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
	if (isSafari) { 
	  document.querySelector('body').classList.add('is-safari')
	};
}

function watchForHover() {
  	// lastTouchTime is used for ignoring emulated mousemove events
  	let lastTouchTime = 0

  	function enableHover() {
    	if (new Date() - lastTouchTime < 500) return
    	document.body.classList.add('has-hover')
  	}

  	function disableHover() {
    	document.body.classList.remove('has-hover')
  	}

  	function updateLastTouchTime() {
    	lastTouchTime = new Date()
  	}	

  	document.addEventListener('touchstart', updateLastTouchTime, true)
  	document.addEventListener('touchstart', disableHover, true)
  	document.addEventListener('mousemove', enableHover, true)

	  enableHover()
	  
	document.querySelector('.open-search').addEventListener('click', () => {
		document.querySelector('.header_search').classList.toggle('is-active')
		document.querySelector('.open-search').classList.toggle('is-active')
	})


}

// REACT DEFINITIONS FOR USAGE OF REACT RAILS - components are imported in view
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

  