import React from 'react'

import { PDFDownloadLink, Page, Text, View, Image, Document, StyleSheet, Font} from '@react-pdf/renderer';


// const styles = StyleSheet.create({
// 	page: {
// 	  flexDirection: 'row',
// 	  backgroundColor: '#E4E4E4'
// 	},
// 	section: {
// 	  margin: 10,
// 	  padding: 10,
// 	  flexGrow: 1
// 	}
//   });

// const MyDocs = () => (
//   	<Document>
//     	<Page size="A4" style={styles.page}>
//       		<View style={styles.section}>
//         		<Text>Section #1</Text>
//       		</View>
//       		<View style={styles.section}>
//         		<Text>Section #2</Text>
//       		</View>
//     	</Page>
//   	</Document>
// );


class PdfCreator extends React.Component {
	constructor(props){
		super(props)
	}

	render() {
		return (
			<div className="download-pdf-link">
			  	<PDFDownloadLink document={<MyDoc pages={this.props.pages} 
				  								  cat={this.props.cat} 
												 	name={this.props.name}
													 filename={this.props.filename}
												  features={this.props.features}/>} 
								 fileName={`${this.props.filename}.pdf`}>
			  		{({ blob, url, loading, error }) => (loading ? 'Please wait for pdf...' : 'Download pdf')}
				</PDFDownloadLink>
			</div>
		);
	}
}

import Montserrat from "../../../public/font-pdf/Montserrat-Regular.ttf"

class MyDoc extends React.Component{
	constructor(props){
		super(props)
		Font.register({
			family: 'Montserrat',
			format: "truetype",
			src: Montserrat
		  });
		this.styles = StyleSheet.create({
			page: {
				flexDirection: 'column',
				backgroundColor: '#fff',
				width: '100%',
				padding: '50px',
			},
			header: {
				height: '100px',
				marginBottom: '20px'
			},
			header_logo: {
				objectFit: 'contain',
			},	
			view: {
				height: '65%',
				padding: 0,
				backgroundColor: 'white',
			},
			image: {
				objectFit: 'contain',
				objectPosition: 'center'
			},
			name: {
				fontFamily: 'Montserrat',
				textAlign: 'center',
				marginTop: '40px',
				fontSize: '25px',
				fontWeight: 'bold',
				textTransform: 'uppercase'
			},
			description: {
				fontFamily: 'Montserrat',
				textAlign: 'center',
				fontSize: '15px',
				padding: '10px'
			},
		});
		this.pages = JSON.parse(this.props.pages)
		this.features = []
		JSON.parse(this.props.features).map(item => {
			this.features.push(`${item['name']}: ${item['value']}`)
		})
		this.features = this.features.join(' - ')
		
		
		this.name = this.props.name
		// console.log(this.props.cat)
		this.props.cat == 'women' ? this.cat = 'W' : this.cat = 'M'
	}

	render(){
		return(
			<Document >
				
				{/* {[0, 1, 2, 3, 4, 5, 6 ,7 ,8 ,9 ,10, 11, 12, 13, 14 ,15 ,16 ,17, 18, 19].map((page) => {
					return (
						<Page size="A4" style={this.styles.page} ref={this.name}>
							<View style={this.styles.header}>
								<Image src={`/images/logo_${this.cat}.png`} style={this.styles.header_logo}/>
							</View>
							<View style={this.styles.view}>
								<Image src={`/test-pdf/${page}.jpg`} style={this.styles.image}/>
							</View>
							<View style={this.styles.name}>
								<Text>{this.name}</Text>
							</View>
							
								
							<View style={this.styles.description} ref={`${this.name}-features`}> 
								<Text>
									{this.features}
								</Text>
							</View>
	
							
						</Page>
					)
				})} */}
				{this.pages.map(page => {
					return (
						<Page size="A4" style={this.styles.page} ref={this.name}>
							<View style={this.styles.header}>
								<Image src={`/images/logo_${this.cat}.png`} style={this.styles.header_logo}/>
							</View>
							<View style={this.styles.view}>
								{/* <Image src={page.thumb.url} style={this.styles.image}/> */}
								<Image src={{ uri: `/utils/getImagesProxy?url=${page.picture.url}`, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} 
										style={this.styles.image}/>
							</View>
							<View style={this.styles.name}>
								<Text>{this.name}</Text>
							</View>
							<View style={this.styles.description} ref={`${this.name}-features`}> 
								<Text>
									{this.features}
								</Text>
							</View>
						</Page>
					)
				})}
			</Document>
		)
	}
}

export default PdfCreator;