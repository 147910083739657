import $ from 'jquery';

$(window).scroll(function() {

    var url;
  
    // Checks if products are currently being loaded
    if (window.pagination_loading) {
      return;
    }
  
    // Grabs the URL from the "next page" button 
    url = $('.pagination .next_page').attr('href')
  
    // Chckes if you're n height from the bottom
    if (url && $(window).scrollTop() > $(document).height() - $(window).height() - 50) {
  
      // Variable to know that you are currently loading products
      window.pagination_loading = true;
  
      // Text while loading
      $('.pagination').text('');
  
      // Run the script
      return $.getScript(url).always(function() {
        return window.pagination_loading = false;
      });
  
    }
  });