import $ from 'jquery';
import 'slick-carousel';


document.addEventListener('DOMContentLoaded',  () => {
    $('.news-index_carousel').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
		prevArrow: '<div class="news-index_carousel_prev"><div class="chevron chevron-left bg-black"></div></div>',
		nextArrow: '<div class="news-index_carousel_next"><div class="chevron bg-black"></div></div>',
        responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 360,
              settings: {
                slidesToShow: 1
              }
            }

		]
    })
})