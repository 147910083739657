import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
import Draggable from 'gsap/Draggable'
const myPlugins = [CSSPlugin, Draggable]
gsap.registerPlugin(Draggable)
import $ from "jquery";
window.jQuery = $;
window.$ = $;

document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector('.h-scroll')){
        
        (function($) {
            document.addEventListener('mousemove', (e) => {
                console.log(e, e.clientX, e.clientY)
                gsap.to('.mouse-pointer', {x: e.clientX - 20, y: e.clientY - 20, duration: 0.1})
                if(e.clientY < document.querySelector('.header').offsetHeight){
                    $('.mouse-pointer').hide()
                } else {
                    $('.mouse-pointer').fadeIn()
                }
            })
            var types = ['DOMMouseScroll', 'mousewheel'];

            if ($.event.fixHooks) {
                for ( var i=types.length; i; ) {
                    $.event.fixHooks[ types[--i] ] = $.event.mouseHooks;
                }
            }

            $.event.special.mousewheel = {
                setup: function() {
                    if ( this.addEventListener ) {
                        for ( var i=types.length; i; ) {
                            this.addEventListener( types[--i], handler, false );
                        }
                    } else {
                        this.onmousewheel = handler;
                    }
                },

                teardown: function() {
                    if ( this.removeEventListener ) {
                        for ( var i=types.length; i; ) {
                            this.removeEventListener( types[--i], handler, false );
                        }
                    } else {
                        this.onmousewheel = null;
                    }
                }
            };

            $.fn.extend({
                mousewheel: function(fn) {
                    return fn ? this.bind("mousewheel", fn) : this.trigger("mousewheel");
                },

                unmousewheel: function(fn) {
                    return this.unbind("mousewheel", fn);
                }
            });


            function handler(event) {
                var orgEvent = event || window.event, args = [].slice.call( arguments, 1 ), delta = 0, returnValue = true, deltaX = 0, deltaY = 0;
                event = $.event.fix(orgEvent);
                event.type = "mousewheel";

                // Old school scrollwheel delta
                if ( orgEvent.wheelDelta ) { delta = orgEvent.wheelDelta/120; }
                if ( orgEvent.detail     ) { delta = -orgEvent.detail/3; }

                // New school multidimensional scroll (touchpads) deltas
                deltaY = delta;

                // Gecko
                if ( orgEvent.axis !== undefined && orgEvent.axis === orgEvent.HORIZONTAL_AXIS ) {
                    deltaY = 0;
                    deltaX = -1*delta;
                }

                // Webkit
                if ( orgEvent.wheelDeltaY !== undefined ) { deltaY = orgEvent.wheelDeltaY/120; }
                if ( orgEvent.wheelDeltaX !== undefined ) { deltaX = -1*orgEvent.wheelDeltaX/120; }

                // Add event and delta to the front of the arguments
                args.unshift(event, delta, deltaX, deltaY);

                return ($.event.dispatch || $.event.handle).apply(this, args);
            }

            })(jQuery);

            // EXTEND jQuery
            $.js = function (el) {
                return $('[data-js=' + el + ']')
            };

            var mainWrapper = $.js('h-scroll');
            var sectionWrapper = $.js('h-scroll_inner');

            var vW = $(window).width();

            mainWrapper.mousewheel(function(event, delta) {
                this.scrollLeft -= (delta * 50);

                event.preventDefault();
            });

            Draggable.create(mainWrapper, {
                type: 'scrollLeft',
                throwProps: true,
                cursor: 'none'
            });

            function setWidth() {
                var section = $.js('section');
                var totalWidth = 0;
                  
                section.each(function() {
                    totalWidth += parseInt($(this).width(), 10);    
                });
                  
              sectionWrapper.css('width', ''+ (totalWidth + 1) +'px');
            }

            setWidth();

            $(window).on('resize', function() {
                setWidth();
              
            });
    }
    
})


