import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power2 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]


const menuMobile = {
	toggle: () => {

		let hamburger = document.querySelector('.js-toggle-menu')
		let menu = document.querySelector('.menu')
		let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
		if (isSafari) { 
		  menu.classList.add('is-safari')
		};

		hamburger.addEventListener('click', () => {
			document.querySelector('html, body').classList.toggle('noscroll')
			document.querySelector('.header').classList.toggle('menu-active')
			if(!menu.classList.contains('is-active')){
				gsap.fromTo( menu.querySelectorAll('.menu_item'), {opacity:0, x:-50}, {duration: 0.5, opacity:1, x:0, stagger:0.1, ease: Power2.out, delay: 0.5})
				// document.addEventListener('click', closeMenuIfOutside)
			} else {
				gsap.to( menu.querySelectorAll('.menu_item'), {opacity:0, x:-50, duration: 0.5})
				// document.removeEventListener('click', closeMenuIfOutside)
			}
			menu.classList.toggle('is-active')
			hamburger.classList.toggle('is-active')
		})
	}
}

document.addEventListener('DOMContentLoaded', () => {
	menuMobile.toggle()
})