import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
var imagesLoaded = require('imagesloaded');

const animateImages = () => {

}

(function() {
  const blurProperty = gsap.utils.checkPrefix("filter"),
        blurExp = /blur\((.+)?px\)/,
        getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

  gsap.registerPlugin({
    name: "blur",
    get(target) {
      return +(getBlurMatch(target)[1]) || 0;
    },
    init(target, endValue) {
      let data = this,
          filter = gsap.getProperty(target, blurProperty),
          endBlur = "blur(" + endValue + "px)",
          match = getBlurMatch(target)[0],
          index;
      if (filter === "none") {
        filter = "";
      }
      if (match) {
        index = filter.indexOf(match);
        endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
      } else {
        endValue = filter + endBlur;
        filter += filter ? " blur(0px)" : "blur(0px)";
      }
      data.target = target; 
      data.interp = gsap.utils.interpolate(filter, endValue); 
    },
    render(progress, data) {
      data.target.style[blurProperty] = data.interp(progress);
    }
  });
})();


const lazyLoad = () => {
	let lazyloadItems = document.querySelectorAll('.lazyload')
	
	lazyloadItems.forEach(i => {
		let src = i.getAttribute('data-src')
		if(src != ''){
			i.src = src
		}
		i.classList.add('lazy-done')
	})

	let homeItems = document.querySelectorAll('.home_item')
	let homeImages = document.querySelectorAll('.home_item img')
	gsap.to(homeItems, {duration: 1, y: 0, opacity: 0.95, stagger: 0.1, ease: Power4.easeOut});
	gsap.to(homeImages, {duration: 5, blur: 0, stagger: 0.1, ease: Power4.easeOut})

}

const homeInit = () => {
	lazyLoad()
}


document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.home')){
		imagesLoaded('.home', homeInit)
	}
	
})