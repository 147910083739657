import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
import { ScrollToPlugin } from "gsap/ScrollToPlugin";


document.addEventListener('DOMContentLoaded',  () => {
	var words=document.querySelectorAll('.text-to-animate');
	words.forEach(word => {
		var text=word.innerHTML;
		text=text.split("");
		var parsed="";
		word.innerHTML = '';
		for(var i=0; i<text.length; i++){
			var tag=document.createElement('span');
			tag.setAttribute('class','js-fade-in-stagger');
			tag.innerHTML=text[i];
			word.append(tag);
		}
	})

	let scrollTo = document.querySelectorAll('.js-scroll-to')
	if(scrollTo){
		scrollTo.forEach(i => {
			i.addEventListener('click', (e) => {
				let id = e.currentTarget.getAttribute('data-id')
				let destination = document.querySelector(`#${id}`)
				gsap.to(window, {duration: 1.5, scrollTo: `#${id}`, ease: "power4"})
			})
		})	
	}

	let backToTop = document.querySelector('.back-to-top')
	if(backToTop){
		document.addEventListener('scroll', () => {
			let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
			if(scrollPos > 200){
				backToTop.classList.add('is-active')
			} else {
				backToTop.classList.remove('is-active')
			}
		})
		
	}
	
})