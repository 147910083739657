import CSSPlugin from 'gsap/CSSPlugin';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
gsap.registerPlugin(ScrollTrigger); 
var imagesLoaded = require('imagesloaded');



export default function() {
  imagesLoaded('body', () => {
    ScrollTrigger.batch(".js-fade-in-stagger", {
      onEnter: (elements, triggers) => {
        gsap.to(elements, {duration: 0.2, y: 0, opacity: 1, pointerEvents: 'unset',  stagger: 0.05});
      }
      // ,
      // onLeave: (elements, triggers) => {
      //   gsap.to(elements, {opacity: 0, stagger: 0.15});
      //   console.log(elements.length, "elements left");
      // }
    });
  })
  
}




// the magical helper function (no longer necessary in GSAP 3.3.1 because it was added as ScrollTrigger.batch())...
function batch(targets, vars) {
  let varsCopy = {},
      interval = vars.interval || 0.1,
      proxyCallback = (type, callback) => {
        let batch = [],
            delay = gsap.delayedCall(interval, () => {callback(batch); batch.length = 0;}).pause();
        return self => {
          batch.length || delay.restart(true);
          batch.push(self.trigger);
          vars.batchMax && vars.batchMax <= batch.length && delay.progress(1);
        };
      },
      p;
  for (p in vars) {
    varsCopy[p] = (~p.indexOf("Enter") || ~p.indexOf("Leave")) ? proxyCallback(p, vars[p]) : vars[p];
  }
  gsap.utils.toArray(targets).forEach(target => {
    let config = {};
    for (p in varsCopy) {
      config[p] = varsCopy[p];
    }
    config.trigger = target;
    ScrollTrigger.create(config);
  });
}