import CSSPlugin from 'gsap/CSSPlugin';
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
import Draggable from 'gsap/Draggable'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin);
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin, Draggable, ScrollTrigger]
// var imagesLoaded = require('imagesloaded');

const slider = {
    init: () => {
        const sliderDivs = document.querySelectorAll('.slider')
        

        let tls = []
        let dir 
        let scrollTime
        sliderDivs.forEach(i => {
            tls.push(gsap.timeline())
        })
        
        sliderDivs.forEach(i => i.addEventListener('mousedown', (e) => {
                e.clientX < window.innerWidth / 2 ? dir = -1 : dir = 1 
                let sliderId = i.getAttribute('data-slider-id')
                let sliderInner = i.querySelector('.slider_inner')
                tls[sliderId].clear()
                if (dir == 1){
                    scrollTime = (sliderInner.offsetWidth -i.scrollLeft) / window.innerWidth * 5
                    tls[sliderId].to(i, {duration: scrollTime, scrollTo:{ x: 'max'}, ease: Power4.easeOut});
                } else {
                    scrollTime = (i.scrollLeft) / window.innerWidth * 5
                    tls[sliderId].to(i, {duration: scrollTime, scrollTo:{ x: 0}, ease: Power4.easeOut});
                }
            })
        )

        sliderDivs.forEach(i => i.addEventListener('mouseup', (e) => {
                let sliderId = i.getAttribute('data-slider-id')
                tls[sliderId].clear()
                tls[sliderId].to(i, {duration: 1, scrollTo:{ x: i.scrollLeft + dir * 200}, ease: Power4.easeOut});
            })
        )
        slider.center_images()
        
        
        ///// pointer rotate ///
        document.addEventListener('mousemove', e => {
            if(e.clientX < window.innerWidth / 2){
                sliderDivs.forEach(i => i.classList.add('arrow-left'))
            } else {
                sliderDivs.forEach(i => i.classList.remove('arrow-left'))
            }
        })
        // HOR scroll
        // gsap.to(slider, {
        //     xPercent: -100,
        //     ease: "none",
        //     scrollTrigger: {
        //       trigger: slider,
        //       pin: true,
        //       scrub: 1,
        //       snap:false,
        //       end: () => "+=" + slider.offsetWidth
        //     }
        //   })


        ///// REDUCE NAME CHANGE GRAPHICS ON SCROLL ////
        let isScrolling;
        document.onmousewheel
        sliderDivs[0].addEventListener('scroll', (e) => {
            window.clearTimeout( isScrolling );

            // Set a timeout to run after scrolling ends

            if(sliderDivs[0].scrollLeft > 15){
                gsap.to('.slider_info_title_inner', {duration: 0.8, scale: 0.5, ease: Power4.easeOut});
                gsap.to('.slider_info_data', {duration: 0.4, y: 0, opacity: 0, ease: Power4.easeOut});
                gsap.to('.line-features_name, .line-features_value', {duration: 0.2, y: 0, opacity: 1, stagger: 0.08});
                gsap.to('.last-name', {duration:0.4, opacity: 0, ease: Power4.easeOut})
                gsap.to('.last-name-inline', {duration:0.4, opacity: 1, ease: Power4.easeOut})
            } else if(sliderDivs[0].scrollLeft < 10) {
                isScrolling = setTimeout(function() {
                    gsap.to('.line-features_name, .line-features_value', {duration: 0.2, y: 8, opacity: 0});
                    gsap.to('.slider_info_title_inner', {duration: 0.8, scale: 1, ease: Power4.easeOut});
                    gsap.to('.slider_info_data', {duration: 0.4, y: 0, opacity: 1, ease: Power4.easeOut});
                    gsap.to('.last-name', {duration:0.4, opacity: 1, ease: Power4.easeOut})
                    gsap.to('.last-name-inline', {duration:0.4, opacity: 0, ease: Power4.easeOut})
                }, 1);
            }
            // gsap.to(sliderInner, {duration: 1, scrollTo:{ x: slider.scrollLeft + e.scrollX}, ease: Power4.easeOut});

        })
    },
    center_images: () => {
        setTimeout( function(){
             let screen_width  = window.innerWidth || document.documentElement.clientWidth
            document.querySelectorAll('.slider').forEach(i => {
                let slider_w = i.querySelector('.slider_inner').offsetWidth
                // console.log(i, slider_w, screen_width)
                if(slider_w < screen_width){
                    i.classList.add('move-to-center')
                } else{
                    i.classList.remove('move-to-center')
                }
            })
        }, 500) 
       
    }
}


document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector('.slider')){
        slider.init()
    }
    window.addEventListener('resize', function(event) {
        slider.center_images()

    }, true);
})