

// USED FOR MODEL PAGES
document.addEventListener('DOMContentLoaded', () => {
    const videoUrlsDiv = document.querySelector('#video-urls')
    if(videoUrlsDiv){
        let videoUrls = JSON.parse(videoUrlsDiv.getAttribute('data-urls'))
        
        let winH = window.innerHeight
        videoUrls.forEach((url, i) => {
            fetch(`https://vimeo.com/api/oembed.json?url=${url}&responsive=true&maxheight=${winH}`)
            .then(response => response.json())
            .then(data => {
                if(data['html']){
                    document.querySelector(`#video-inner-${i}`).innerHTML = data['html']
                }
            })
        })
    }
})

// USED FOR NEWS AND HOME
document.addEventListener('DOMContentLoaded', () => {
    const videoBlockDivs = document.querySelectorAll('.js-video-block')
    
    if (videoBlockDivs.length > 0){
        videoBlockDivs.forEach(i => {
            let id = i.getAttribute('data-id')
            let winH = window.innerHeight
            let url = i.getAttribute('data-url')
            let autoplay = JSON.parse(i.getAttribute('data-autoplay'))
            let autoplayParams = ''
            if(autoplay == true){
                autoplayParams = '&autoplay=1&loop=1&muted=true&controls=false&background=1'
            }
            fetch(`https://vimeo.com/api/oembed.json?url=${url}&responsive=true&maxheight=${winH}${autoplayParams}`)
            .then(response => response.json())
            .then(data => {
                if(data['html']){
                    document.querySelector(`#video-inner-${id}`).innerHTML = data['html']
                }
            })
        })
    }
})